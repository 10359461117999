.preloader,
.preloader:before,
.preloader:after {
    background: #0d6efd;
    animation: animation 1s infinite ease-in-out;
    width: 12px;
}

.preloader:before,
.preloader:after {
    position: absolute;
    content: '';
}

.preloader:before {
    left: -20px;
    animation-delay: -0.32s;
}

.preloader {
    margin: 65px auto 40px;
    transform: translateZ(0);
    animation-delay: -0.16s;
}

.preloader:after {
    left: 20px;
}

@keyframes animation {
    0%,
    80%,
    100% {
        box-shadow: 0 0 #0d6efd;
        height: 40px;
    }
    40% {
        box-shadow: 0 -30px #0d6efd;
        height: 80px;
    }
}